import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import Footer from '../components/Footer'
import RecipeGrid from '../components/RecipeGrid'

// Export Template for use in CMS preview
export const HomePageTemplate = ({ title, subtitle, featuredImage, body, recipes, posts }) => {

  return (
    <main className="Home">
      <PageHeader
        large
        title='Receitas sem glúten'
        subtitle=''
        backgroundImage={featuredImage}
        cta='Procurar Receita'
        options={["fit", "low-carb", "vegetarianas", "keto", "paleo",
                  "e sem lactose", "veganas", "nutritivas", "gostosas"]}
      />

      <section className="section">
        <div className="container">
          <RecipeGrid title='Últimas Receitas' tiles={recipes} />
        </div>
        <br />
        <div className="container">
          <RecipeGrid title='Últimos Artigos' tiles={posts} />
        </div>
      </section>
    </main>
)}

// Export Default HomePage for front-end
const HomePage = ({ data: { page, recipes, posts } }) => {
  const now = Date.now()

  var publishedRecipes = recipes.edges.filter(edge => edge.node.frontmatter.status == 'Published');
  var publishedPosts = posts.edges.filter(edge => edge.node.frontmatter.status == 'Published');

  publishedRecipes = publishedRecipes.filter(edge => Date.parse(edge.node.frontmatter.date) <= now)
  publishedPosts = publishedPosts.filter(edge => Date.parse(edge.node.frontmatter.date) <= now)

  return (
    <div>
      <HomePageTemplate {...page} {...page.frontmatter} body={page.html} recipes={publishedRecipes} posts={publishedPosts} />
      <Footer />
    </div>
)}

export default HomePage

export const pageQuery = graphql`
  ## Query for HomePage data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query HomePage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        subtitle
        featuredImage
      }
    }
    recipes: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "recipes" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            status
            categories {
              category
            }
            tags {
              tag
            }
            featuredImage
          }
        }
      }
    }
    posts: allMarkdownRemark(
      filter: { fields: { contentType: { in: ["posts", "quizzes"] } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date
            status
            categories {
              category
            }
            featuredImage
          }
        }
      }
    }
  }
`
